import { Injectable } from "@angular/core";
import { StoreService } from "./store.service";

@Injectable({
  providedIn: "root",
})
export class PanelAccessConfigService {
  constructor(private StoreService: StoreService) { }

  private cms_routes: any = [];
  private admin_routes: any = [];
  private formedMenu: any;

  formUrl(megaMenu: any) {
    for (let item in megaMenu) {
      for (let panelSubMenu in megaMenu[item]) {
        if (panelSubMenu == "have_project" || panelSubMenu == "panel_base") continue;
        for (let lang in megaMenu[item][panelSubMenu]) {
          this.createURL(megaMenu[item][panelSubMenu][lang], megaMenu, item, panelSubMenu);
        }
      }
    }
    this.formedMenu = megaMenu;
    return this.formedMenu;
  }

  createURL(menuItems: any, megaMenu: any, item: any, panelSubMenu: any) {
    for (let menuItem of menuItems) {
      let path = "";
      switch (megaMenu[item].panel_base) {
        case "administration_system":
          path = this.setAdminPanelRoutes(megaMenu, item, menuItem);
          break;
        case "crm":
        case "modules":
          path = this.noProjectPanelRoutes(megaMenu, item, menuItem);
          break;
        case "cms":
          path = this.setCmsPanelRoutes(megaMenu, item, menuItem, panelSubMenu);
          break;
        default:
          break;
      }
      if (!path.includes("ParentMenu")) {
        menuItem["router_link"] = path;
      }
      if ("items" in menuItem) {
        this.createURL(menuItem.items, megaMenu, item, panelSubMenu);
      }
    }
  }

  noProjectPanelRoutes(megaMenu: any, item: any, menuItem: any) {
    return `${megaMenu[item].panel_base}/${menuItem["staticComp"]}/${menuItem["costumComp"]}`;
  }

  setCmsPanelRoutes(megaMenu: any, item: any, menuItem: any, panelSubMenu: any) {
    const path = `cms/${item}/${panelSubMenu}/${menuItem["staticComp"]}/${menuItem["costumComp"]}`;
    if (!path.includes("ParentMenu")) {
      menuItem["router_link"] = `${item}/${panelSubMenu}/${menuItem["staticComp"]}/${menuItem["costumComp"]}`;
      menuItem["project"] = panelSubMenu;
      menuItem["api"] = item;
      this.cms_routes.push({ ...menuItem });
      //@ts-ignore
      window["cms_routes"] = this.cms_routes;
    }
    return path;
  }

  setAdminPanelRoutes(megaMenu: any, item: any, menuItem: any) {
    const path = `${megaMenu[item].panel_base}/${menuItem["staticComp"]}/${menuItem["costumComp"]}`;
    if (!path.includes("ParentMenu")) {
      menuItem["router_link"] = `${menuItem["staticComp"]}/${menuItem["costumComp"]}`;
      menuItem["project"] = megaMenu[item].panel_base;
      menuItem["api"] = item;
      this.admin_routes.push({ ...menuItem });
      //@ts-ignore

      window["admin_routes"] = this.admin_routes;
    }
    return path;
  }

  setCurrentUrl(menuList: any) {
    if (!this.formedMenu) {
      this.formedMenu = this.formUrl(menuList);
    }
    const url: any = [];
    const savedPath = localStorage.getItem(this.StoreService.getUser().email + "path");
    if (savedPath) {
      let url2 = savedPath?.replace(/\%3f/g, "?").replace(/\%3d/g, "=").replace(/\%3F/g, "?").replace(/\%3D/g, "=");
      if (url2.charAt(0) == '/') {
        url2 = url2.substring(1)
      }

      const url_sep = url2.split("?");
      const qurey_list: any = {};
      if (url_sep.length > 1) {
        url_sep[1].split(",").forEach((el: any) => {
          const _query = el.split("=");
          qurey_list[_query[0]] = _query[1];
        });
      }

      const pathInMenu = this.checkUrlInMenu(url_sep, this.formedMenu);
      if (savedPath && pathInMenu) {
        return [savedPath];
      }
    }

    const searchMenu = this.formedMenu[Object.keys(this.formedMenu)[0]];

    for (const project in searchMenu) {
      if (project == "have_project" || project == "panel_base") continue;
      for (let lang in searchMenu[project]) {
        if (this.firstLoginLinkPrepare(searchMenu[project][lang], url)) {
          return url;
        }
      }
    }
  }

  checkUrlInMenu(savedPath: any, menu: any) {
    let stringMenu = JSON.stringify(menu);
    return stringMenu.includes(savedPath);
  }

  firstLoginLinkPrepare(menuItems: any, url: any) {
    for (let menuItem of menuItems) {
      if (menuItem.items) {
        this.firstLoginLinkPrepare(menuItem.items, url);
      } else {
        if (!menuItem.staticComp.includes("ParentMenu")) {
          if (url.length === 0) {
            url.push(`/${menuItem.router_link}`);
            return true;
          }
        }
      }
    }
    return true;
  }
}
